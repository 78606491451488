@import "~antd/dist/antd.css";
@import "~antd/lib/style";
@import "~antd/lib/icon/style";

$bg-gray: #dbdbdb;
$lighter-gray: #edeef1;
$bg-blue: #f0f5fe;
$icon-blue: #389afb;

#root {
  min-height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

.selected-tab {
  span {
    color: #389afb;
  }
}
